var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('navigation.staff'))+" ")]},proxy:true},(_vm.hasAccess('users', 'create'))?{key:"button",fn:function(){return [_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.createUpdateModal",modifiers:{"createUpdateModal":true}}],staticClass:"ml-auto",attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t('button.add'))+" "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)]},proxy:true}:null],null,true)}),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"card"},[_c('b-table',{attrs:{"fields":_vm.tableHeader,"items":_vm.users,"responsive":true,"borderless":"","outlined":"","striped":""},scopedSlots:_vm._u([{key:"cell(index)",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"cell(role)",fn:function(ref){
var item = ref.item;
return [(item.role)?[_vm._v(" "+_vm._s(item.role.name)+" ")]:_vm._e()]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('table-action-btns',{attrs:{"delete-access":{ section: 'users', permission: 'delete' },"delete-handler":_vm.deleteUser,"index":index,"item":item,"update-access":{ section: 'users', permission: 'update' }},on:{"openModal":_vm.openModalToUpdate}})]}}])})],1)])],1),_c('b-modal',{attrs:{"id":"createUpdateModal","hide-footer":false,"no-close-on-backdrop":true,"title":_vm.$t('add.staff'),"cancel-variant":"danger","centered":"","size":"sm"},on:{"hidden":_vm.onCloseModal,"show":_vm.onOpenModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.modalSubmit}},[_vm._v(" "+_vm._s(_vm.$t('button.add'))+" ")])]},proxy:true}]),model:{value:(_vm.isOpenModal),callback:function ($$v) {_vm.isOpenModal=$$v},expression:"isOpenModal"}},[_c('ValidationObserver',{ref:"createUpdateModal"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.fio')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.fio'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter.fio'),"size":"lg"},model:{value:(_vm.dataForModal.name),callback:function ($$v) {_vm.$set(_vm.dataForModal, "name", $$v)},expression:"dataForModal.name"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.email')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter.email'),"autocomplete":"off","size":"lg"},model:{value:(_vm.dataForModal.email),callback:function ($$v) {_vm.$set(_vm.dataForModal, "email", $$v)},expression:"dataForModal.email"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.role')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.role'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('v-select',{class:[
                                    'select-size-lg',
                                    {
                                        'v-select--is-invalid': errors.length > 0
                                    }
                                ],attrs:{"options":_vm.userRoles,"placeholder":_vm.$t('choose.role'),"reduce":function (role) { return role.id; },"searchable":false,"label":"name"},model:{value:(_vm.dataForModal.role_id),callback:function ($$v) {_vm.$set(_vm.dataForModal, "role_id", $$v)},expression:"dataForModal.role_id"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.password')))]),_c('form',[_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.password'),"rules":_vm.updatingItem === null ? 'required|min:6' : 'min:6'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-group',[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter.password'),"type":_vm.passwordFieldType,"autocomplete":"off","size":"lg"},model:{value:(_vm.dataForModal.password),callback:function ($$v) {_vm.$set(_vm.dataForModal, "password", $$v)},expression:"dataForModal.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1)],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }