<script>
import {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BPagination,
    BRow,
    BTable
} from 'bootstrap-vue';
import api from '@/services/api';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import vSelect from 'vue-select';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import PageTitle from '@/components/PageTitle';
import { updateObjectToNew } from '@/util/helper';

export default {
    name: 'User',
    components: {
        PageTitle,
        TableActionBtns,
        BTable,
        BRow,
        BCol,
        BPagination,
        BFormGroup,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        vSelect
    },
    mixins: [togglePasswordVisibility, CRUDPageMixin],
    data: () => ({
        users: [],
        userRoles: [],
        dataForModal: {
            name: '',
            email: '',
            role_id: null,
            password: ''
        }
    }),
    computed: {
        tableHeader() {
            return [
                {
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'name',
                    label: this.$t('titles.name')
                },
                {
                    key: 'email',
                    label: this.$t('titles.email')
                },
                {
                    key: 'role',
                    label: this.$t('titles.role')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
        }
    },
    methods: {
        async getRoles() {
            const { data } = await api.roles.get();
            this.userRoles = data;
        },

        async getUsers() {
            const { data } = await api.users.get();
            this.users = data;
        },

        async create() {
            const { data } = await api.users.post(this.dataForModal);
            this.users.push(data);
        },

        async update() {
            const { data } = await api.users.put(this.updatingItem.id, this.dataForModal);
            this.users.forEach((item) => {
                if (item.id === this.updatingItem.id) {
                    updateObjectToNew(item, data);
                }
                return item;
            });
        },

        async deleteUser(id, index) {
            return await api.users.delete(id).then((res) => {
                if (res) this.users.splice(index, 1);
            });
        },

        onOpenModal() {
            if (!this.userRoles.length) {
                this.getRoles();
            }
        }
    },
    mounted() {
        this.getUsers();
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title>
                {{ $t('navigation.staff') }}
            </template>
            <template v-if="hasAccess('users', 'create')" #button>
                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <b-row>
            <!--  TABLE  -->
            <b-col cols="12">
                <div class="card">
                    <b-table
                        :fields="tableHeader"
                        :items="users"
                        :responsive="true"
                        borderless
                        outlined
                        striped
                    >
                        <template #cell(index)="{ index }">
                            {{ index + 1 }}
                        </template>
                        <template #cell(role)="{ item }">
                            <template v-if="item.role">
                                {{ item.role.name }}
                            </template>
                        </template>
                        <template #cell(actions)="{ item, index }">
                            <table-action-btns
                                :delete-access="{ section: 'users', permission: 'delete' }"
                                :delete-handler="deleteUser"
                                :index="index"
                                :item="item"
                                :update-access="{ section: 'users', permission: 'update' }"
                                @openModal="openModalToUpdate"
                            />
                        </template>
                    </b-table>
                </div>
            </b-col>
        </b-row>

        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="false"
            :no-close-on-backdrop="true"
            :title="$t('add.staff')"
            cancel-variant="danger"
            centered
            size="sm"
            @hidden="onCloseModal"
            @show="onOpenModal"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    FULL_NAME    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.fio') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.fio')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name"
                                    :placeholder="$t('enter.fio')"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    EMAIL    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.email') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.email')"
                            rules="required|email"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.email"
                                    :placeholder="$t('enter.email')"
                                    autocomplete="off"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    ROLE    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.role') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.role')"
                            rules="required"
                        >
                            <b-form-group>
                                <v-select
                                    v-model="dataForModal.role_id"
                                    :class="[
                                        'select-size-lg',
                                        {
                                            'v-select--is-invalid': errors.length > 0
                                        }
                                    ]"
                                    :options="userRoles"
                                    :placeholder="$t('choose.role')"
                                    :reduce="(role) => role.id"
                                    :searchable="false"
                                    label="name"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    NEW_PASSWORD    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.password') }}</label>
                        <form>
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('titles.password')"
                                :rules="updatingItem === null ? 'required|min:6' : 'min:6'"
                            >
                                <b-form-group>
                                    <b-input-group>
                                        <b-form-input
                                            v-model="dataForModal.password"
                                            :placeholder="$t('enter.password')"
                                            :type="passwordFieldType"
                                            autocomplete="off"
                                            size="lg"
                                        />
                                        <b-input-group-append is-text>
                                            <feather-icon
                                                :icon="passwordToggleIcon"
                                                class="cursor-pointer"
                                                @click="togglePasswordVisibility"
                                            />
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                                <span class="validation__red">
                                    {{ errors[0] }}
                                </span>
                            </ValidationProvider>
                        </form>
                    </b-col>
                </b-row>
            </ValidationObserver>
            <template #modal-footer>
                <b-button variant="success" @click="modalSubmit">
                    {{ $t('button.add') }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
